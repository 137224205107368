/**
 * Do not edit directly
 * Generated on Thu, 20 Feb 2025 17:14:04 GMT
 */

:root {
  --brand-color-background: 0 0 5;
  --brand-color-background-secondary: 25 25 25;
  --brand-color-background-disabled: 30 30 30;
  --brand-color-border: 252 252 255;
  --brand-color-text: 252 252 255;
  --brand-color-text-secondary: 252 252 255;
}
